import { useQuery } from 'react-query';
import { getUserSession } from 'utils/auth';

export default function useUserSession() {
  return useQuery({
    queryKey: 'userSession',
    queryFn: async () => await getUserSession(),
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });
}
