import { QueryClient } from 'react-query';
import * as LDClient from 'launchdarkly-js-client-sdk';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000 * 5,
      cacheTime: 60 * 1000 * 10,
      refetchOnWindowFocus: false,
    },
  },
});

export const ldClient = LDClient.initialize(
  process.env.LAUNCH_DARKLY_CLIENT_ID,
  { key: 'anonymous' },
  { bootstrap: 'localStorage' }
);

export const ready = new Promise(resolve => {
  ldClient.on('ready', () => {
    resolve(ldClient);
  });
});
