import { BehaviorSubject, distinctUntilChanged, skip } from 'rxjs';

const rawCart = localStorage.getItem('cart') || '[]';
const defaultCart = JSON.parse(rawCart);
export const shoppingCart = new BehaviorSubject(defaultCart);
export const shoppingCartSource = shoppingCart.pipe(skip(1)).pipe(distinctUntilChanged());
shoppingCartSource.subscribe(newCart => {
  localStorage.setItem('cart', JSON.stringify(newCart));
});

export const cartOpen = new BehaviorSubject(false);
export const cartOpenSource = cartOpen.pipe(skip(1)).pipe(distinctUntilChanged());
