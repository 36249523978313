import * as React from 'react';
import { shoppingCart, shoppingCartSource, cartOpen, cartOpenSource } from './observables';

export const useShoppingCart = () => {
  const [cart, setCart] = React.useState<any[]>(shoppingCart.value);
  const [open, setOpen] = React.useState<boolean>(cartOpen.value);

  React.useEffect(() => {
    const cartSubscription = shoppingCartSource.subscribe(newCart => {
      setCart(newCart);
    });

    const cartOpenSubscription = cartOpenSource.subscribe(newOpen => {
      setOpen(newOpen);
    });

    return () => {
      cartSubscription.unsubscribe();
      cartOpenSubscription.unsubscribe();
    };
  }, []);

  const setShoppingCart = (cart: any[]) => {
    shoppingCart.next(cart);
  };

  const clear = callback => {
    const oldCart = shoppingCart.value;
    setShoppingCart([]);
    if (callback) {
      callback(oldCart);
    }
  };

  const addLotToCart = lot => {
    setShoppingCart([...shoppingCart.value, lot]);
  };

  const addLotsToCart = (lots: any[]) => {
    setShoppingCart([...shoppingCart.value, ...lots]);
  };

  const removeListing = (tagNumber: string) => {
    setShoppingCart(shoppingCart.value.filter(item => item.tagNumber !== tagNumber));
  };

  const openCart = () => {
    cartOpen.next(true);
  };

  const closeCart = () => {
    cartOpen.next(false);
  };

  const toggleOpen = () => {
    cartOpen.next(!cartOpen.value);
  };

  return {
    shoppingCart: cart,
    clear,
    addLotToCart,
    addLotsToCart,
    removeListing,
    open,
    openCart,
    closeCart,
    toggleOpen,
  };
};
