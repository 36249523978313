import { useAuthorizationToken, useCurrentUser } from 'hooks';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateAccountSettingsRequest, updateAccountSettings } from 'api';

export const useUserPreferences = () => {
  const { data: currentUser } = useCurrentUser();
  const defaultLocale = localStorage.getItem('preferredLocale') || 'en-US';

  return {
    preferredCurrency: currentUser?.preferredCurrency ?? 'USD',
    measurementSystem: currentUser?.measurementSystem ?? 'IMPERIAL',
    preferredLocale: currentUser?.preferredLocale ?? defaultLocale,
    shippingLocation: currentUser?.shippingLocation,
    shippingLocationPostalCode:
      currentUser?.shippingLocationPostalCode ?? localStorage.getItem('shippingLocation'),
  };
};

export const useUpdateUserPreferences = () => {
  const { data: currentUser } = useCurrentUser();
  const currentPreferences = useUserPreferences();
  const queryClient = useQueryClient();
  const authToken = useAuthorizationToken();

  return useMutation(
    (request: UpdateAccountSettingsRequest) => {
      if (currentUser) {
        return updateAccountSettings(authToken, request);
      }
    },
    {
      onMutate: async request => {
        // Cancel current queries for the currentUser
        await queryClient.cancelQueries('currentUser');

        // Create optimistic updated user
        if (currentUser) {
          const updatedUser = { ...currentUser };
          updatedUser.preferredCurrency =
            request.preferredCurrency ?? currentUser.preferredCurrency;
          updatedUser.measurementSystem =
            request.measurementSystem ?? currentUser.measurementSystem;
          updatedUser.preferredLocale = request.preferredLocale ?? currentUser.preferredLocale;
          updatedUser.shippingLocation = request.shippingLocation ?? currentUser.shippingLocation;
          updatedUser.shippingLocationPostalCode =
            request.shippingLocationPostalCode ?? currentUser.shippingLocationPostalCode;

          // Update local state optimistically
          queryClient.setQueryData('currentUser', updatedUser);
        }

        if (request.preferredLocale) {
          localStorage.setItem('preferredLocale', request.preferredLocale);
        }

        if (request.shippingLocation) {
          localStorage.setItem('shippingLocation', request.shippingLocation);
        }

        return {
          request,
          previousUser: currentUser,
          previousPreferences: currentPreferences,
        };
      },
      onSuccess: (result, variables, context) => {
        if (currentUser) {
          queryClient.setQueryData('currentUser', {
            ...currentUser,
            ...result,
          });
        }
      },
      onError: (error, variables, context) => {
        if (currentUser) {
          queryClient.setQueryData('currentUser', context.previousUser);
        }

        if (context.request.preferredLocale) {
          localStorage.setItem('preferredLocale', context.previousUser.preferredLocale);
        }
      },
      retry: 3,
    }
  );
};
