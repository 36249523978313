// import * as AWS from 'aws-sdk/global';
import jwtDecode from 'jwt-decode';
import { CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

const userPool = new CognitoUserPool({
  UserPoolId: process.env.COGNITO_USER_POOL_ID as string,
  ClientId: process.env.COGNITO_CLIENT_ID as string,
});

interface OverridedCognitoUser extends CognitoUser {
  username?: string;
}

export type UserSession = {
  cognitoUser: OverridedCognitoUser;
  cognitoGroups: string[];
  isReibusUser: boolean;
  identityToken: string | undefined;
};

type CognitoAccessToken = {
  auth_time: number;
  client_id: string;
  'cognito:groups': string[];
  device_key: string;
  event_id: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  scope: string;
  sub: string;
  token_use: string;
  username: string;
};

export const getUserSession = (): Promise<UserSession | null> => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err || !session) {
          reject(err ? err : new Error('error authenticating - no user session'));
        }

        const awsUserInformation: UserSession = {
          cognitoUser,
          cognitoGroups: [],
          isReibusUser: false,
          identityToken: session?.getIdToken().getJwtToken(),
        };

        const accessToken = (session as CognitoUserSession).getAccessToken().getJwtToken();
        const atDecoded = jwtDecode<CognitoAccessToken | undefined>(accessToken);

        if (atDecoded?.[`cognito:groups`]) {
          awsUserInformation.cognitoGroups = atDecoded[`cognito:groups`];

          if (atDecoded[`cognito:groups`].indexOf('reibus') !== -1) {
            awsUserInformation.isReibusUser = true;
          }
        }
        resolve(awsUserInformation);
      });
    } else {
      reject({
        status: 401,
        name: 'error authenticating',
        message: 'error authenticating',
      });
    }
  });
};

export const logout = () => {
  const cognitoUser = userPool.getCurrentUser();
  return new Promise(resolve => {
    cognitoUser?.signOut(() => {
      resolve(true);
    });
  });
};
