import { useMutation, useQueryClient } from 'react-query';
import { logout } from 'utils/auth';
import useCurrentUser from './useCurrentUser';
import useUserSession from './useUserSession';

const KEYS_TO_PRESERVE_IN_LOCALSTORAGE = [
  'unreadNotificationCount',
  'algoliasearch-client-js',
  'hasGDPR',
  'import-map-override',
  'devtools',
  'i18nextLng',
  'shippingLocation',
  'ld:',
];

export default function useLogout() {
  const queryClient = useQueryClient();
  const { data: userSession } = useUserSession();
  const { data: currentUser } = useCurrentUser();

  return useMutation(logout, {
    onMutate: async () => {
      await queryClient.cancelQueries('userSession');
      await queryClient.cancelQueries('currentUser');

      queryClient.setQueryData('userSession', null);
      queryClient.setQueryData('currentUser', null);

      const localStorageToRemove = Object.keys(localStorage)
        .filter(key => !KEYS_TO_PRESERVE_IN_LOCALSTORAGE.some(preserve => key.startsWith(preserve)))
        .reduce((memo: Record<string, string>, key) => {
          memo[key] = localStorage.getItem(key);
          return memo;
        }, {});

      Object.keys(localStorageToRemove).forEach(keyToRemove =>
        localStorage.removeItem(keyToRemove)
      );

      return {
        userSession,
        currentUser,
        localStorage: localStorageToRemove,
      };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData('userSession', context.userSession);
      queryClient.setQueryData('currentUser', context.currentUser);

      // restore localStorage?
      Object.entries(context.localStorage).forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });
    },
  });
}
