import { useQuery } from 'react-query';
import { getUser } from 'api/user';
import useAuthorizationToken from './useAuthorizationToken';
import { getUserSession } from 'utils/auth';

export default function useCurrentUser() {
  const authToken = useAuthorizationToken();

  return useQuery(
    ['currentUser'],
    async () => {
      const session = await getUserSession();
      if (!session) {
        return null;
      }

      const currentUser = await getUser(session.identityToken);
      return currentUser;
    },
    {
      enabled: !!authToken,
    }
  );
}
