import axios from 'axios';
import type { MeasurementSystem, User } from 'types';

type GetUserResponseType = {
  user: User;
  statusText: string;
};

export type UpdateAccountSettingsRequest = {
  preferredCurrency?: string;
  measurementSystem?: MeasurementSystem;
  preferredLocale?: string;
  shippingLocation?: string;
  shippingLocationPostalCode?: string;
};

export const getUser = async (Authorization: string): Promise<User> => {
  const url = `${process.env.API_URL}/users`;
  const config = {
    headers: {
      Authorization,
    },
  };

  const response = await axios.get<GetUserResponseType>(url, config);
  if (response.status === 200) {
    return response.data.user;
  } else {
    throw new Error(response.statusText);
  }
};

export const updateAccountSettings = async (
  Authorization: string,
  request: UpdateAccountSettingsRequest
) => {
  const updateLocalSettingsRequest = {
    ...request,
    updateType: 'updateLocalSettings',
  };

  const config = {
    headers: {
      Authorization,
    },
  };

  const result = await axios.put(
    `${process.env.API_URL}/users`,
    updateLocalSettingsRequest,
    config
  );
  return result.data.user;
};
