import * as React from 'react';

const useEffectIgnoreMount = (effect: React.EffectCallback, deps?: React.DependencyList) => {
  const mountRef = React.useRef(false);

  React.useEffect(() => {
    if (mountRef.current) {
      return effect();
    } else {
      mountRef.current = true;
    }
  }, deps);
};

export default useEffectIgnoreMount;
